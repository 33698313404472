import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';
import MobileDetect from 'mobile-detect';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

var marquee = require('jquery.marquee');

var siteGlobal = {
  initReady: function () {
  	this.mobileDetect();
  },
  initLoad: function () {
    this.customSelect();
  	this.lazyLoad();
    this.pageSlides();
    this.topSliderMessage();
    this.mobMenuInit();
  },
  initScroll: function(){
  },
  mobileDetect: function() {
    var detector = new MobileDetect(window.navigator.userAgent);
  	if (detector.mobile()){
      $('html').addClass('is-touch');
    } else {
      $('html').addClass('no-touch');
    }
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazyload',
        use_native: true
    })
  },
  customSelect: function(){
    $('.wpcf7 select').each(function(){
        var $this = $(this), 
          numberOfOptions = $(this).children('option').length,
          classList = $(this).attr('class').split(',');;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options ' + classList
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('active').next('ul.select-options').slideToggle(150);
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
        });
      
        $listItems.on('click', function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $(this).parent().siblings('.select-styled').addClass('sel');
        });
    });
  },
  pageSlides: function(){
    if ($('.pageBlocks__slides').length) {
      $('.pageBlocks__slides').slick({
        slidesToShow:1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        speed: 600,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        lazyLoad: 'progressive'
      });
    }
  },
  topSliderMessage: function(){
    if ($('.top-slider-message').length) {
      $('.top-slider-message').slick();
      $('.top-slider-message').show();
    }
  },
  mobMenuInit: function () {
    $('.nav-toggle-mobile').on('click', function () {
      $('html').toggleClass('nav-open');
    });
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());